<template>
    <div>
        <el-tag :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false" @close="handleClose(tag)">
            {{ tag }}
        </el-tag>
        <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="mini" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm" />
        <el-button v-else class="button-new-tag" size="small" @click="showInput">+ Ny tagg</el-button>
    </div>
</template>
<script>
export default {
    props: {
        dynamicTags: {
            type: Array,
        },
    },

    watch: {
        dynamicTags: {
            immediate: true,
            handler(newVal) {
                this.tags = [...newVal];
            },
        },
    },

    data() {
        return {
            tags: [],
            inputVisible: false,
            inputValue: "",
        };
    },

    methods: {
        handleClose(tag) {
            this.dynamicTags.splice(this.tags.indexOf(tag), 1);
        },

        showInput() {
            this.inputVisible = true;
            this.$nextTick(() => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },

        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                this.tags.push(inputValue);
                this.$emit("updateTags", this.tags);
            }
            this.inputVisible = false;
            this.inputValue = "";
        },
    },
};
</script>
<style>
.el-tag {
    margin-right: 10px;
}

.button-new-tag {
    margin-right: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.input-new-tag {
    width: 90px;
    margin-right: 10px;
    vertical-align: bottom;
}
</style>
